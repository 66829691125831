import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import filter from "lodash/filter";
import sortBy from "lodash/sortBy";
import maxBy from "lodash/maxBy";
import compose from "lodash/fp/compose";

import {
  Alert,
  Box,
  Button,
  FormControl,
  IconButton,
  TextField,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  CircularProgress,
  Backdrop,
} from "@mui/material";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from "@mui/icons-material/Check";
import NewReleases from "@mui/icons-material/NewReleases";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SettingsIcon from "@mui/icons-material/Settings";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SyncIcon from "@mui/icons-material/Sync";

import withUserInfo from "./HOC/withUserInfo";

import LessonOptionsModal from "./LessonOptionsModal";
import DisableableLink from "./general/DisableableLink";
import { PUBLISHED_STATUS } from "./constants";
import { dateToSimpleString } from "./utils";

const SINGLE_COLUMN_FIELDS = ["type", "author", "sequence", "release_status"];
const SEARCHABLE_FIELDS = {
  sequence: "Number",
  type: "Type",
  title: "Title",
  author: "Author",
  release_name: "Release",
  last_updated: "Updated",
  release_status: "Status",
};

const LIST_COLUMNS = [
  {
    field: "latestBranch",
    flex: 1,
    headerName: "Latest",
    filterable: false,
    align: "center",
    renderCell: (params) => (params.value ? <NewReleases /> : ""),
  },
  ...Object.keys(SEARCHABLE_FIELDS).map((field) => ({
    field: field,
    flex: SINGLE_COLUMN_FIELDS.indexOf(field) !== -1 ? 1 : 2,
    headerName: SEARCHABLE_FIELDS[field],
    renderCell: (params) => (
      <Highlight
        value={
          params.value instanceof Date
            ? dateToSimpleString(params.value)
            : params.value
        }
      />
    ),
  })),
  {
    field: "edit",
    flex: 1,
    headerName: "Edit",
    headerAlign: "center",
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    align: "center",
    renderCell: (params) => (
      <DisableableLink
        to={`/lesson/${params.id}`}
        disabled={params.value.disabled}
      >
        <IconButton disabled={params.value.disabled}>
          <EditIcon />
        </IconButton>
      </DisableableLink>
    ),
  },
  {
    field: "clone",
    flex: 1,
    headerName: "Clone",
    headerAlign: "center",
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    align: "center",
    renderCell: (params) => (
      <IconButton
        data-test-selector="clone-lesson"
        onClick={params.value.cloneLessonHandler}
      >
        <FileCopyIcon />
      </IconButton>
    ),
  },
  {
    field: "sync",
    flex: 1,
    headerName: "Sync",
    headerAlign: "center",
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    align: "center",
    renderCell: (params) => (
      <IconButton
        data-test-selector="sync-lesson"
        onClick={params.value.syncLessonHandler}
      >
        <SyncIcon />
      </IconButton>
    ),
  },
  {
    field: "options",
    flex: 1,
    headerName: "Options",
    headerAlign: "center",
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableReorder: true,
    align: "center",
    renderCell: (params) => (
      <IconButton
        onClick={params.value.editOptionsHandler}
        disabled={params.value.disabled}
      >
        <SettingsIcon />
      </IconButton>
    ),
  },
];

function Highlight(props) {
  return <div dangerouslySetInnerHTML={{ __html: props.value }}></div>;
}

const LessonListToolbar = (props) => () =>
  (
    <GridToolbarContainer className="dq-flex dq-space-x-5">
      <GridToolbarFilterButton />
      <GridToolbarColumnsButton />
      {props.filters.map((filter) => {
        const active = props.activeFilterFunctions.includes(
          filter.filterFunction
        );
        return (
          <Button
            key={filter.text}
            variant="outlined"
            color={active ? "secondary" : undefined}
            size="small"
            onClick={() => props.updateFilters(filter.filterFunction)}
          >
            {filter.text}
            {active ? <CheckIcon fontSize="small" /> : null}
          </Button>
        );
      })}
    </GridToolbarContainer>
  );

class LessonList extends Component {
  state = {
    lessons: [],
    filteredLessons: [],
    adding: false,
    showingOptions: false,
    newLessonSequence: "",
    newLessonTitle: "",
    createError: "",
    creatingLesson: false,
    editError: "",
    searchText: "",
    activeFilterFunctions: [],
    optionEditLesson: undefined,
    loading: false,
    loadingLessons: false,
  };

  filters = [
    {
      text: "Lesson only",
      filterFunction: (lesson) => lesson.type === "mission",
    },
    {
      text: "Assessment only",
      filterFunction: (lesson) => lesson.type === "assessment",
    },
    {
      text: "Authored by me only",
      filterFunction: (lesson) => lesson.author === this.props.userInfo.name,
    },
    {
      text: "Unpublished only",
      filterFunction: (lesson) => lesson.release_status !== PUBLISHED_STATUS,
    },
  ];

  componentDidMount() {
    this.fetchLessons();
  }

  fetchLessons() {
    this.setState({ loadingLessons: true });
    fetch("/api/lessons")
      .then((data) => data.json())
      .then((lessons) => {
        lessons = lessons.filter((lesson) => !lesson.is_shallow_import);
        lessons = lessons.map((lesson) => {
          const sameLessons = filter(
            lessons,
            (l) => lesson.sequence === l.sequence
          );
          const lastLesson = maxBy(sameLessons, (l) =>
            new Date(l.last_updated).getTime()
          );

          return {
            ...lesson,
            latestBranch: lastLesson?.id === lesson.id,
            last_updated: new Date(lesson.last_updated),
            edit: {
              editLessonHandler: () => this.editLessonHandler(lesson.id),
              disabled: lesson.release_status === PUBLISHED_STATUS,
            },
            clone: {
              cloneLessonHandler: () => this.cloneLessonHandler(lesson.id),
            },
            sync: {
              syncLessonHandler: () => this.syncLessonHandler(lesson.id),
            },
            options: {
              editOptionsHandler: () => this.editOptionsHandler(lesson.id),
              disabled: lesson.release_status === PUBLISHED_STATUS,
            },
          };
        });

        // Only display the latest lessons to external authors
        if (this.props.userInfo?.external) {
          lessons = filter(lessons, (l) => l.latestBranch);
        }

        lessons = sortBy(lessons, "sequence");

        this.setState({
          lessons: lessons,
          filteredLessons: lessons,
          loadingLessons: false,
        });
      })
      .catch((error) => {
        this.setState({ loadingLessons: false });
        throw error;
      });
  }

  addLesson = () => {
    this.setState({ createError: "", creatingLesson: true });
    const sequence = this.state.newLessonSequence;
    const errorMsg = "An error occurred while creating the lesson.";
    fetch("/api/lessons", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sequence: sequence,
        title: this.state.newLessonTitle,
      }),
    })
      .then((data) => data.json())
      .then((body) => {
        // Correctly created lesson always has the ID value
        if (body.error || body.id === undefined) {
          this.setState({
            createError: body.error || errorMsg,
            creatingLesson: false,
          });
          return;
        }
        this.setState({ createError: "", creatingLesson: false });
        this.props.history.push(`/lesson/${body.id}`);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          createError: errorMsg,
          creatingLesson: false,
        });
      });
  };

  renderAddLesson() {
    if (!this.state.adding)
      return (
        <Button
          color="primary"
          variant="contained"
          data-test-selector="add-lesson"
          onClick={() => this.setState({ adding: true })}
        >
          New Lesson
        </Button>
      );
    return (
      <div className="dq-w-full">
        {this.state.createError ? (
          <Box color="error.main" p={1} data-test-selector="lesson-error">
            {this.state.createError}
          </Box>
        ) : null}

        <FormControl size="small">
          <TextField
            type="number"
            margin="dense"
            error={this.state.createError !== ""}
            value={this.state.newLessonSequence}
            label="Number"
            data-test-selector="lesson-number-input"
            onChange={(event) =>
              this.setState({ newLessonSequence: event.target.value })
            }
          />
          <TextField
            margin="dense"
            value={this.state.newLessonTitle}
            label="Title"
            data-test-selector="lesson-title-input"
            onChange={(event) =>
              this.setState({ newLessonTitle: event.target.value })
            }
          />
          <Button
            color="primary"
            variant="contained"
            disabled={
              this.state.newLessonSequence === "" || this.state.creatingLesson
            }
            onClick={this.addLesson}
            data-test-selector="create-lesson"
            className="dq-mt-5"
          >
            {this.state.creatingLesson ? (
              <CircularProgress size={24} />
            ) : (
              "Create"
            )}
          </Button>
        </FormControl>
      </div>
    );
  }

  updateSearch = (searchText) => {
    const filteredLessons = this.applyFilters(
      searchText,
      this.state.activeFilterFunctions
    );
    this.setState({
      searchText: searchText,
      filteredLessons: filteredLessons,
    });
  };

  filterLesson = (searchStr) => (lesson) => {
    return Object.keys(SEARCHABLE_FIELDS).some((field) =>
      String(lesson[field]).toLocaleLowerCase().includes(searchStr)
    );
  };

  highlightLesson = (searchStr, lesson) => {
    if (searchStr === "") return lesson;
    const regex = new RegExp(searchStr, "gi");
    const highlightedLesson = { ...lesson };
    Object.keys(SEARCHABLE_FIELDS).forEach(
      (k) =>
        (highlightedLesson[k] = String(lesson[k]).replace(
          regex,
          (match) =>
            `<span style="background: rgba(245, 0, 87, 0.25);">${match}</span>`
        ))
    );
    return highlightedLesson;
  };

  updateFilters = (filterToUpdate) => {
    let currentFilters = this.state.activeFilterFunctions;
    if (currentFilters.includes(filterToUpdate)) {
      currentFilters = currentFilters.filter(
        (filter) => filter !== filterToUpdate
      );
    } else {
      currentFilters = [...currentFilters, filterToUpdate];
    }
    const filteredLessons = this.applyFilters(
      this.state.searchText,
      currentFilters
    );
    this.setState({
      activeFilterFunctions: currentFilters,
      filteredLessons: filteredLessons,
    });
  };

  applyFilters = (searchText, filterFunctions) => {
    searchText = searchText.toLocaleLowerCase();
    // Apply search box filter
    let filteredLessons = this.state.lessons.filter(
      this.filterLesson(searchText)
    );
    // Apply button filters
    filterFunctions.forEach((filter) => {
      filteredLessons = filteredLessons.filter(filter);
    });
    filteredLessons = filteredLessons.map((lesson) =>
      this.highlightLesson(searchText, lesson)
    );
    return filteredLessons;
  };

  editOptionsHandler = (lessonId) => {
    const lesson = this.state.lessons.filter(
      (lesson) => lesson.id === lessonId
    )[0];
    this.setState({
      showingOptions: true,
      optionEditLesson: lesson,
    });
  };

  editLessonHandler = (lessonId) => {
    const lesson = this.state.lessons.filter(
      (lesson) => lesson.id === lessonId
    )[0];
    if (lesson.release_status !== PUBLISHED_STATUS) {
      this.props.history.push(`/lesson/${lessonId}`);
    }
  };

  syncLessonHandler = (lessonId) => {
    this.setState({ loading: true, editError: "" });
    const errorMsg = "An error occurred while sychronizing the lesson.";
    fetch(`/api/lesson/${lessonId}/sync`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
    })
      .then((data) => data.json())
      .then((body) => {
        if (body.error || body.id === undefined) {
          this.setState({
            loading: false,
            editError: body.error || errorMsg,
          });
          return;
        }
        this.setState({ loading: false, editError: "" });
        this.props.history.push(`/lesson/${body.id}`);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
          editError: errorMsg,
        });
      });
  };

  cloneLessonHandler = (lessonId) => {
    this.setState({ loading: true, editError: "" });
    const errorMsg = "An error occurred while cloning the lesson.";
    fetch(`/api/lessons/${lessonId}/version/new`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
    })
      .then((data) => data.json())
      .then((body) => {
        // Correctly created lesson always has the ID value
        if (body.error || body.id === undefined) {
          this.setState({
            loading: false,
            editError: body.error || errorMsg,
          });
          return;
        }
        this.setState({ loading: false, editError: "" });
        this.props.history.push(`/lesson/${body.id}`);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
          editError: errorMsg,
        });
      });
  };

  setShowingOptions = (showingOptions) => {
    this.setState({ showingOptions: showingOptions });
  };

  renderSearchBar() {
    return (
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="outlined-adornment-amount">Search</InputLabel>
        <OutlinedInput
          id="outlined-adornment-amount"
          value={this.state.searchText}
          onChange={(event) => this.updateSearch(event.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          labelWidth={60}
          endAdornment={
            this.state.searchText ? (
              <InputAdornment position="end">
                <IconButton onClick={() => this.updateSearch("")}>
                  <HighlightOffIcon />
                </IconButton>
              </InputAdornment>
            ) : null
          }
        />
      </FormControl>
    );
  }

  renderTable = () => {
    if (this.state.loadingLessons) {
      return <CircularProgress />;
    }
    return (
      <DataGrid
        autoHeight
        className="dq-flex dq-w-4/5 dq-mx-auto dq-shadow"
        classes="dq-w-full dq-flex dq-flex-grow"
        rows={this.state.filteredLessons}
        columns={LIST_COLUMNS}
        disableSelectionOnClick
        disableColumnMenu
        components={{
          Toolbar: LessonListToolbar({
            updateFilters: this.updateFilters,
            filters: this.filters,
            activeFilterFunctions: this.state.activeFilterFunctions,
          }),
        }}
      />
    );
  };

  closeLessonOptionModalAndReload = () => {
    this.setShowingOptions(false);
    this.fetchLessons();
  };

  render() {
    return (
      <>
        <Backdrop
          style={{ zIndex: 1050 }} // TODO: Fix this hardcoded zIndex
          open={this.state.loading}
        >
          <CircularProgress />
        </Backdrop>

        {this.state.showingOptions && (
          <LessonOptionsModal
            open={this.setShowingOptions}
            lesson={this.state.optionEditLesson}
            closeModalAndReload={this.closeLessonOptionModalAndReload}
            closeModal={() => this.setShowingOptions(false)}
          />
        )}
        <Box display="flex" alignItems="center" flexDirection="column">
          <div className="dq-flex dq-w-4/5 dq-my-5 dq-justify-between">
            <h1 className="dq-font-bold dq-text-2xl dq-w-2/3 dq-m-0">
              Lessons
            </h1>
            {this.renderAddLesson()}
          </div>

          <div className="dq-flex dq-w-4/5 dq-my-5 dq-justify-between">
            {this.renderSearchBar()}
          </div>

          {this.state.editError && (
            <Alert severity="error" data-test-selector="lesson-error">
              {this.state.editError}
            </Alert>
          )}
          {this.renderTable()}
        </Box>
      </>
    );
  }
}

const withAll = compose(withUserInfo());

export default withRouter(withAll(LessonList));
