import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { lessonDataActions } from "../store/lessonDataSlice";
import { errorActions } from "../store/errorSlice";
import { codeEditorActions } from "../store/codeEditorSlice";
import withCodeEditor from "../HOC/withCodeEditor";
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Checkbox,
  LinearProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CUSTOM_TEST_TAB } from "./code-tabs/constants";

function VariableSection({ lesson, screen, codeEditor }) {
  const dispatch = useDispatch();
  const selectedVariables = codeEditor.selectedVariables;
  const latestVariables = React.useMemo(() => {
    const imageVars = (codeEditor.runResult?.output?.images || []).map(
      (image, i) => ({
        name: `dq_plot${i + 1}`,
        base_class: "<class 'dq_plot'>",
        html_output: false,
        length: `${image}`.length,
        string_form: `${image}`,
        type_name: "dq_plot",
      })
    );
    return [...imageVars, ...codeEditor.runResult.variables];
  }, [codeEditor.runResult]);

  const displayVariables =
    latestVariables.length > 0 ? latestVariables : screen.answer_variables;

  const [savingVariables, setSavingVariables] = useState(false);

  const containsVariable = (variables, variable) => {
    return variables.filter((v) => v.name === variable.name).length > 0;
  };

  useEffect(() => {
    dispatch(
      codeEditorActions.setSelectedVariables({
        screenId: screen.id,
        selectedVariables: screen.answer_variables.filter((variable) =>
          containsVariable(latestVariables, variable)
        ),
      })
    );
  }, [latestVariables]);

  if (codeEditor.currentTab === CUSTOM_TEST_TAB) return null;

  const selectVariable = async (variable) => {
    let newSelectedVars;
    if (containsVariable(selectedVariables, variable)) {
      newSelectedVars = selectedVariables.filter(
        (v) => v.name !== variable.name
      );
    } else {
      newSelectedVars = [...selectedVariables, variable];
    }
    dispatch(
      codeEditorActions.setSelectedVariables({
        screenId: screen.id,
        selectedVariables: newSelectedVars,
      })
    );
    setSavingVariables(true);
    try {
      const response = await fetch(
        `/api/lesson/${lesson.id}/screen/${screen.id}/variables`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(newSelectedVars),
        }
      );
      const responseData = await response.json();
      if (responseData.error) {
        dispatch(
          errorActions.setScreenError({
            screenId: screen.id,
            error: responseData.error,
          })
        );
      } else {
        dispatch(
          lessonDataActions.setScreenData({
            screen: responseData,
          })
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        errorActions.setScreenError({
          screenId: screen.id,
          error: "An error occurred while trying toggle variable",
        })
      );
    }
    setSavingVariables(false);
  };

  const renderCheckbox = (variable) => {
    if (latestVariables.length === 0) return null;
    return (
      <FormControlLabel
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        control={
          <Checkbox
            style={{ color: "white" }}
            size="small"
            checked={containsVariable(selectedVariables, variable)}
            onChange={() => selectVariable(variable)}
          />
        }
      />
    );
  };

  const renderVariable = (variable) => (
    <Accordion key={variable.name}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
      >
        {renderCheckbox(variable)}
        <Box className="variable-summary">
          <div>{variable.name}</div>
          <div>
            {variable.type_name} ({variable.base_class})
          </div>
        </Box>
      </AccordionSummary>
      <AccordionDetails className="output-text">
        {variable.name.includes("dq_plot") ? (
          <img src={variable.string_form} alt={`${variable.name}`} />
        ) : (
          variable.string_form
        )}
      </AccordionDetails>
    </Accordion>
  );

  if (displayVariables.length === 0) return null;

  return (
    <Box className="output-variables">
      <Box p={1} color="white" bgcolor="primary.main">
        Variables
      </Box>
      {savingVariables && <LinearProgress />}
      {displayVariables.map(renderVariable)}
    </Box>
  );
}

export default withCodeEditor(VariableSection);
