import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import LessonMenu from "../lessons/LessonMenu";
import { Box, IconButton, LinearProgress } from "@mui/material";
import BuildNotification from "../lessons/BuildNotification";
import ScreenFooter from "./ScreenFooter";
import ScreenError from "./ScreenError";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ReviewPanel from "../review/ReviewPanel";
import ScreenText from "./ScreenText";
import { lessonDataActions } from "../store/lessonDataSlice";
import withLessonData from "../HOC/withLessonData";
import { errorActions } from "../store/errorSlice";
import ScreenCode from "./ScreenCode";

function Screen({ lesson, screen, history }) {
  const dispatch = useDispatch();

  let screenStatus = useSelector((state) => state.lessonData.screenStatus);
  let multipleChoiceQuestions = useSelector(
    (state) => state.multipleChoiceQuestions
  );

  const [textCollapsed, setTextCollapsed] = useState(false);
  const [codeCollapsed, setCodeCollapsed] = useState(false);
  const [commentCollapsed, setCommentCollapsed] = useState(true);

  if (!screen) {
    history.replace(`/lesson/${lesson.id}`);
    return null;
  }

  screenStatus = screenStatus[screen.id];
  multipleChoiceQuestions = multipleChoiceQuestions[screen.id];

  const saveScreen = async (saveMessage = "") => {
    if (typeof saveMessage !== "string" && !(saveMessage instanceof String)) {
      saveMessage = "";
    }
    try {
      dispatch(lessonDataActions.startSavingScreen(screen.id));
      let body = {
        title: screen.title,
        type: screen.type,
        body_markdown: screen.body_markdown,
        instructions_markdown: screen.instructions_markdown,
        hint_markdown: screen.hint_markdown,
        chat_prompt: screen.chat_prompt,
        setup_code: screen.setup_code,
        initial_code: screen.initial_code,
        display_code: screen.display_code,
        should_run_display_code: screen.should_run_display_code,
        answer_code: screen.answer_code,
        custom_check_code: screen.custom_check_code,
        is_experimental: screen.is_experimental,
        remote_run_code: screen.remote_run_code,
        remote_run_only: screen.remote_run_only,
        error_okay: screen.error_okay,
        dynamic_answer: screen.dynamic_answer,
        format_code_run: screen.format_code_run,
        check_output: screen.check_output,
        check_val: screen.check_val,
        check_scope: screen.check_scope,
        legacy_check_code: screen.legacy_check_code,
        multipleChoiceQuestions: multipleChoiceQuestions,
        saveMessage: saveMessage,
      };
      const response = await fetch(
        `/api/lesson/${lesson.id}/screen/${screen.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      );
      if (response.status !== 200) {
        dispatch(
          errorActions.setScreenError({
            screenId: screen.id,
            error: `Could not fetch code run results`,
          })
        );
      }
      const responseData = await response.json();
      if (responseData.error) {
        dispatch(
          errorActions.setScreenError({
            screenId: screen.id,
            error: responseData.error,
          })
        );
      } else {
        dispatch(
          lessonDataActions.setScreenData({
            screen: responseData,
            backend: true,
          })
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        errorActions.setScreenError({
          screenId: screen.id,
          error: "An error occurred while saving the screen.",
        })
      );
    }
    dispatch(lessonDataActions.stopSavingScreen(screen.id));
  };

  const getSectionWidth = () => {
    const opened =
      !textCollapsed + (!codeCollapsed && isCodeScreen()) + !commentCollapsed;
    if (opened === 3) return "33%";
    if (opened === 2) return "50%";
    return "100%";
  };

  const isCodeScreen = () => {
    return screen.type === "code";
  };

  const renderTextSection = () => {
    if (textCollapsed) return null;

    return (
      <Box className="editor-section" style={{ width: getSectionWidth() }}>
        <ScreenText lesson={lesson} screen={screen} saveScreen={saveScreen} />
      </Box>
    );
  };

  const renderCodeSection = () => {
    if (!isCodeScreen() || codeCollapsed) return null;

    return (
      <Box className="editor-section" style={{ width: getSectionWidth() }}>
        <ScreenCode lesson={lesson} screen={screen} saveScreen={saveScreen} />
      </Box>
    );
  };

  const renderCommentSection = () => {
    if (commentCollapsed) return null;
    return (
      <Box className="editor-section" style={{ width: getSectionWidth() }}>
        <ReviewPanel screenId={screen.id} />
      </Box>
    );
  };

  const renderCommentCollapseControls = () => (
    <IconButton
      size="small"
      onClick={() => setCommentCollapsed(!commentCollapsed)}
    >
      <ChatBubbleIcon className="output-text-stderr" />
    </IconButton>
  );

  const collapseCodeSection = () => {
    setCodeCollapsed(true);
    if (textCollapsed) {
      setTextCollapsed(false);
    }
  };

  const collapseTextSection = () => {
    setTextCollapsed(true);
    if (codeCollapsed) {
      setCodeCollapsed(false);
    }
  };

  const renderCollapseControls = () => {
    if (!isCodeScreen()) return null;

    if (textCollapsed) {
      return (
        <IconButton size="small" onClick={() => setTextCollapsed(false)}>
          <ChevronRightIcon />
        </IconButton>
      );
    }
    if (codeCollapsed) {
      return (
        <IconButton size="small" onClick={() => setCodeCollapsed(false)}>
          <ChevronLeftIcon />
        </IconButton>
      );
    }
    return (
      <>
        <IconButton size="small" onClick={collapseTextSection}>
          <ChevronLeftIcon />
        </IconButton>
        <IconButton size="small" onClick={collapseCodeSection}>
          <ChevronRightIcon />
        </IconButton>
      </>
    );
  };

  return (
    <Box
      className="editor"
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <LessonMenu
        title={`${lesson.sequence}-${screen.sequence}: ${screen.title}`}
        save={saveScreen}
      >
        <BuildNotification lesson={lesson} />
      </LessonMenu>
      <Box className="editor-container">
        {screenStatus.saving && <LinearProgress />}
        <Box className="editor-body">
          {renderTextSection()}
          <Box className="editor-collapse-controls">
            {renderCollapseControls()}
          </Box>
          {renderCodeSection()}
          <Box className="editor-collapse-controls">
            {renderCommentCollapseControls()}
          </Box>
          {renderCommentSection()}
        </Box>
      </Box>
      <ScreenFooter lesson={lesson} screen={screen} saveScreen={saveScreen} />
      <ScreenError screen={screen} />
    </Box>
  );
}

export default withRouter(withLessonData(Screen));
