import React from "react";
import { useSelector } from "react-redux";
import { Box, List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { RUN_CODE_TAB } from "./code-tabs/constants";

export default function DynamicChecksSection({ lesson, screen }) {
  const codeEditor = useSelector((state) => state.codeEditor)[screen.id];

  const currentTab = codeEditor.currentTab;
  const { checks, variables } = codeEditor.runResult;

  const varsByName = React.useMemo(() => {
    const vbn = {};
    variables.forEach((variable) => {
      vbn[variable.name] = variable;
    });
    return vbn;
  }, [variables]);

  if (!checks || !checks.enabled) return null;
  if (currentTab !== RUN_CODE_TAB) return null;

  const stringifyVariable = (variable) => {
    const str = `${variable.base_class} with value ${variable.string_form};`;
    if (variable.length != null) return `${str} of length ${variable.length}`;
    return str;
  };

  const renderVariableValue = (value) => {
    if (value.image) return <img alt="expected-plot" src={value.image} />;
    return stringifyVariable(value);
  };

  const renderExpectedSection = (expected, gotVar) => {
    return (
      <Box>
        <Box>Expected</Box>
        <Box style={{ paddingLeft: "2em", color: "green" }}>
          {renderVariableValue(expected)}
        </Box>
        {gotVar && (
          <Box style={{ paddingLeft: "2em", color: "red" }}>
            {renderVariableValue(gotVar)}
          </Box>
        )}
      </Box>
    );
  };

  const renderCheck = (check) => {
    const { expected, actual, pass } = check;
    if (pass) return null;

    const varName = expected.name;
    const gotVar = actual || varsByName[varName];
    return (
      <ListItem alignItems="flex-start">
        <ListItemIcon>
          <CloseIcon fontSize="large" color="error" />
        </ListItemIcon>
        <ListItemText
          primary={varName}
          primaryTypographyProps={{
            fontFamily: "monospace",
            color: "white",
            sx: {
              fontWeight: "bold",
            },
          }}
          secondaryTypographyProps={{
            fontFamily: "monospace",
            color: "white",
          }}
          secondary={
            <React.Fragment>
              <Box>
                {check.feedback.map((feedback) => (
                  <Box>{feedback}</Box>
                ))}
                {renderExpectedSection(expected, gotVar)}
                {check.hints && check.hints.map((hint) => <Box>{hint}</Box>)}
              </Box>
            </React.Fragment>
          }
        />
      </ListItem>
    );
  };

  const renderChecks = () => {
    if (!checks?.results?.length) return null;
    return (
      <List dense className="check-messages">
        {checks.results.map(renderCheck)}
      </List>
    );
  };

  return (
    <Box className="output-custom">
      <Box>
        <Box
          p={1}
          color="white"
          bgcolor={checks.pass ? "primary.main" : "error.main"}
        >
          Dynamic checks
        </Box>
        {checks.pass ? (
          <Box className="output-text">All passed.</Box>
        ) : (
          <Box className="output-text output-text-stderr">
            Wrong answer:{" "}
            {checks.results?.filter((res) => !res.pass).length || "All"}{" "}
            check(s) failed.
          </Box>
        )}
        {renderChecks(checks)}
      </Box>
    </Box>
  );
}
