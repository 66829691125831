export const INITIAL_CODE_TAB = "initial";
export const DISPLAY_CODE_TAB = "display";
export const ANSWER_CODE_TAB = "answer";
export const CUSTOM_CHECK_TAB = "custom";
export const VALUE_CHECK_TAB = "value_check";
export const CUSTOM_TEST_TAB = "test_custom";
export const LEGACY_CHECK_TAB = "legacy";
export const CLI_TEST_TAB = "test_cli";
export const SETUP_CODE_TAB = "setup";
export const RUN_CODE_TAB = "run_code";

export const REMOTE_RUN_CODE_TAB = "remote_run_code";
export const FORMAT_CODE_RUN_TAB = "format_code_run";

export let DEFAULT_SETUP_CODE =
  "# Setup code for stateful command line runners provides a mechanism\n";
DEFAULT_SETUP_CODE +=
  "# to set up the runner for specific screens. This code is executed only\n";
DEFAULT_SETUP_CODE +=
  "# on runner setup, meant to update the runner's state the runner with \n";
DEFAULT_SETUP_CODE +=
  "# screen specific pre-requisites that were completed in previous \n";
DEFAULT_SETUP_CODE += "# screens, but get lost in new runner sessions.";

export const DEFAULT_FORMAT_CODE_RUN =
  "def format_var(var):\n" +
  "    # Format variable for more flexible answer checking\n" +
  "    # Each variable is represented as a dictionary:\n" +
  '    # {"name": str, "type_name": str, "base_class": str, "length": int, "string_form": str, "html_output": str}\n' +
  "    return var\n";

export const DEFAULT_REMOTE_RUN_CODE =
  "# Bash command to launch a remote application. All commands will be executed in a daemon mode as `bash -c '<your command>'&`.\n" +
  "# Previously launched commands are interrupted before subsequent execution.\n" +
  "# Code submission (initial + answer for the authoring app) is written into scripts.py file before launch.\n" +
  "# Works with stateful runners only.\n";

export const TAB_LABELS = {
  [INITIAL_CODE_TAB]: "Initial",
  [DISPLAY_CODE_TAB]: "Display",
  [ANSWER_CODE_TAB]: "Answer",
  [CUSTOM_CHECK_TAB]: "Custom",
  [VALUE_CHECK_TAB]: "Answer Value",
  [CUSTOM_TEST_TAB]: "Test",
  [LEGACY_CHECK_TAB]: "Legacy",
  [CLI_TEST_TAB]: "Test",
  [SETUP_CODE_TAB]: "Setup",
  [REMOTE_RUN_CODE_TAB]: "Deploy code",
  [FORMAT_CODE_RUN_TAB]: "Format code run",
  [RUN_CODE_TAB]: "Run code",
};
