import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  IconButton,
  Stack,
  Tooltip,
  Alert,
  Checkbox,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SearchableTable from "../general/SearchableTable";
import { useHistory } from "react-router-dom";
import { LEVELS, TRACK_TYPES } from "../general/constants";
import NewEntryForm from "../general/NewEntryForm";
import ImportModal from "../general/ImportModal";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyModal from "../general/CopyModal";

const FILTERS = [];

function TrackList(props) {
  const history = useHistory();

  const [importModalIsOpen, setImportModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tracks, setTracks] = useState([]);
  const [creatingNew, setCreatingNew] = useState(false);
  const [trackName, setTrackName] = useState("");
  const [creatingTrack, setCreatingTrack] = useState(false);
  const [error, setError] = useState("");
  const [trackToCopy, setTrackToCopy] = useState();

  const COLUMNS = [
    {
      key: "sequence",
      label: "Number",
      type: "text",
    },
    {
      key: "type",
      label: "Type",
      type: "component",
      render: (track, col) => TRACK_TYPES[track.type],
    },
    {
      key: "name",
      label: "Name",
      type: "text",
    },
    {
      key: "release_name",
      label: "Release",
      type: "text",
    },
    {
      key: "version",
      label: "Version",
      type: "text",
    },
    {
      key: "hidden",
      label: "Default Version",
      type: "component",
      render: (track, col) => (
        <Checkbox checked={!track.hidden} disabled={true} />
      ),
    },
    {
      key: "skill_level",
      label: "Level",
      type: "component",
      render: (track, col) => LEVELS[track.skill_level],
    },
    {
      key: "num_courses",
      label: "Num Courses",
      type: "text",
    },
    {
      key: "is_main",
      label: "Is Main",
      type: "text",
    },
    {
      key: "duration",
      label: "Time Estimate",
      type: "component",
      render: (track, col) => (track.duration ? `${track.duration}h` : ""),
    },
    {
      key: "edit",
      label: "",
      type: "component",
      render: (track, col) => (
        <IconButton onClick={() => history.push(`/track/${track.id}`)}>
          <EditIcon />
        </IconButton>
      ),
    },
    {
      key: "copy",
      label: "",
      type: "component",
      render: (track, col) => (
        <Tooltip title="Copy to another release">
          <IconButton onClick={() => setTrackToCopy(track)}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const updateTrackList = async () => {
    setIsLoading(true);
    const response = await fetch(`/api/tracks`);
    const tracks = await response.json();
    setTracks(tracks);
    setIsLoading(false);
  };

  const handleCreateTrack = async (sequence, name) => {
    try {
      setError("");
      setCreatingTrack(true);
      const response = await fetch("/api/track", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sequence: sequence,
          name: name,
        }),
      });
      const data = await response.json();
      if (data.error) {
        setError(data.error);
      } else {
        updateTrackList();
      }
      setTrackName("");
      setCreatingTrack(false);
    } catch (error) {
      setCreatingTrack(false);
      setError("An error occurred while creating the path. Try again later.");
    }
  };

  useEffect(() => {
    updateTrackList();
  }, []);

  const importTracks = async (releaseId, trackSequences) => {
    return fetch(`/api/tracks/release/${releaseId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sequences: trackSequences,
      }),
    });
  };

  const copyTrack = async (releaseId) => {
    return fetch(`/api/track/${trackToCopy.id}/release/${releaseId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
  };

  const closeImportHandler = () => {
    setImportModalIsOpen(false);
    updateTrackList();
  };

  const closeCopyHandler = () => {
    setTrackToCopy();
    updateTrackList();
  };

  return (
    <Container sx={{ pb: 4 }}>
      <ImportModal
        isOpen={importModalIsOpen}
        closeHandler={closeImportHandler}
        title="Import Tracks"
        import={importTracks}
      />
      <CopyModal
        isOpen={trackToCopy}
        closeHandler={closeCopyHandler}
        title="Copy Track Into Another Release"
        copy={copyTrack}
        renderObjectToCopy={() =>
          trackToCopy && (
            <Typography>
              {`${trackToCopy.sequence} - ${trackToCopy.name} (${trackToCopy.release_name})`}
            </Typography>
          )
        }
      />
      <Box my={5}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4">Paths</Typography>
          <Stack direction="row" spacing={1}>
            <NewEntryForm
              variant="outlined"
              newLabel="New Path"
              addLabel="Create Path"
              nameLabel="Path Name"
              handleAdd={handleCreateTrack}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => setImportModalIsOpen(true)}
            >
              Import
            </Button>
          </Stack>
        </Stack>
      </Box>

      {error && <Alert severity="error">{error}</Alert>}

      <SearchableTable
        cols={COLUMNS}
        rows={tracks}
        isLoading={isLoading}
        filters={FILTERS}
        rowsPerPage={10}
      />
    </Container>
  );
}

export default TrackList;
