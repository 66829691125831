import React, { useState } from "react";
import {
  Stack,
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Badge,
  Alert,
  LinearProgress,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import SkillLevelSelect from "../general/SkillLevelSelect";
import WysiwygEditor from "../general/WysiwygEditor";

export default function TrackMetadata(props) {
  const [track, setTrack] = useState(props.track);
  const [hasChanges, setHasChanges] = useState(false);
  const [error, setError] = useState("");
  const [saving, setSaving] = useState(false);

  const disabled = saving || props.track.is_main;

  const updateField = (field, value) => {
    setHasChanges(true);
    setTrack({ ...track, [field]: value });
  };

  const handleAddObjective = () => {
    setHasChanges(true);
    setTrack({ ...track, objectives: [...track.objectives, ""] });
  };

  const editObjective = (index, value) => {
    setHasChanges(true);
    const newObjectives = track.objectives.map((objective, i) =>
      i === index ? value : objective
    );
    setTrack({ ...track, objectives: newObjectives });
  };

  const deleteObjective = (index) => {
    setHasChanges(true);
    const newObjectives = track.objectives.filter(
      (objective, i) => i !== index
    );
    setTrack({ ...track, objectives: newObjectives });
  };

  const handleSaveChanges = async () => {
    try {
      setError("");
      setSaving(true);
      setHasChanges(false);
      await new Promise((resolve) => setTimeout(resolve, 3000));

      const response = await fetch(`/api/track/${props.track.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: track.name,
          version: track.version,
          type: track.type,
          description: track.description,
          short_description: track.short_description,
          objectives: track.objectives,
          slug: track.slug,
          skill_level: track.skill_level,
          duration: track.duration,
          overview: track.overview,
          motivation: track.motivation,
          outcomes: track.outcomes,
          hidden: track.hidden,
        }),
      });
      const data = await response.json();
      if (data.error) {
        setError(data.error);
      }
      props.reloadTrack();
    } catch (error) {
      console.log(error);
      setError(
        "An error occurred while trying to save changes. Try again later."
      );
    } finally {
      setSaving(false);
    }
  };

  const renderHeader = () => (
    <Stack direction="row" justifyContent="flex-end">
      <Badge color="secondary" variant="dot" invisible={!hasChanges}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveChanges}
          disabled={disabled}
        >
          Save Changes
        </Button>
      </Badge>
    </Stack>
  );

  const renderObjective = (index) => (
    <Stack direction="row" key={`objective-${index}`}>
      <TextField
        fullWidth
        value={track.objectives[index]}
        onChange={(event) => editObjective(index, event.target.value)}
        disabled={disabled}
        size="small"
      />
      <IconButton
        color="secondary"
        onClick={() => deleteObjective(index)}
        disabled={disabled}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  );

  const renderObjectives = () => (
    <Stack direction="column" spacing={1}>
      {track.objectives.map((objective, index) => renderObjective(index))}
      <Button fullWidth onClick={handleAddObjective} disabled={disabled}>
        <AddIcon />
      </Button>
    </Stack>
  );

  const renderLeftForm = () => {
    return (
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography variant="subtitle2">Path Name</Typography>
          <TextField
            onChange={(event) => updateField("name", event.target.value)}
            value={track.name}
            fullWidth
            disabled={disabled}
            size="small"
          />
        </Box>
        <Box>
          <Typography variant="subtitle2">Path Version</Typography>
          <TextField
            onChange={(event) => {
              if (event.target.value === "" || event.target.value > 0)
                updateField("version", event.target.value);
            }}
            value={track.version}
            type="number"
            fullWidth
            disabled={disabled}
            size="small"
            InputProps={{
              inputProps: { min: 0.1 },
            }}
          />
        </Box>
        <Box>
          <Typography variant="subtitle2">Default Version</Typography>
          <Checkbox
            color="primary"
            checked={!track.hidden}
            disabled={disabled}
            onChange={(event) => updateField("hidden", !track.hidden)}
          />
        </Box>
        <Box>
          <Typography variant="subtitle2">Description</Typography>
          <WysiwygEditor
            initValue={track.description}
            handleOnChange={(value) => updateField("description", value)}
            readOnly={disabled}
          />
        </Box>
        <Box>
          <Typography variant="subtitle2">Short Description</Typography>
          <WysiwygEditor
            initValue={track.short_description || ""}
            handleOnChange={(value) => updateField("short_description", value)}
            readOnly={disabled}
          />
        </Box>
        <Box>
          <Typography variant="subtitle2">Overview</Typography>
          <TextField
            onChange={(event) => updateField("overview", event.target.value)}
            value={track.overview}
            minRows={3}
            multiline
            fullWidth
            disabled={disabled}
            size="small"
          />
        </Box>
        <Box>
          <Typography variant="subtitle2">Learning Objectives</Typography>
          {renderObjectives()}
        </Box>
      </Stack>
    );
  };

  const renderRightForm = () => {
    return (
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography variant="subtitle2">Path Type</Typography>
          <Select
            fullWidth
            value={track.type}
            onChange={(event) => updateField("type", event.target.value)}
            disabled={disabled}
            size="small"
          >
            <MenuItem value={"career"}>Career Path</MenuItem>
            <MenuItem value={"skill"}>Skill Path</MenuItem>
          </Select>
        </Box>
        <Box>
          <Typography variant="subtitle2">Page Slug</Typography>
          <TextField
            onChange={(event) => updateField("slug", event.target.value)}
            value={track.slug}
            fullWidth
            disabled={disabled}
            size="small"
          />
        </Box>
        <Box>
          <Typography variant="subtitle2">Level</Typography>
          <SkillLevelSelect
            fullWidth
            value={track.skill_level}
            onChange={(event) => updateField("skill_level", event.target.value)}
            disabled={disabled}
            size="small"
          />
        </Box>
        <Box>
          <Typography variant="subtitle2">
            Time Estimate to Completion
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextField
              onChange={(event) =>
                setTrack({ ...track, duration: event.target.value })
              }
              value={track.duration || 0}
              disabled={disabled}
              type="number"
              size="small"
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
            <Typography>Hours</Typography>
          </Stack>
        </Box>
        <Box>
          <Typography variant="subtitle2">Motivation</Typography>
          <WysiwygEditor
            initValue={track.motivation || ""}
            handleOnChange={(value) => updateField("motivation", value)}
            readOnly={disabled}
          />
        </Box>
        <Box>
          <Typography variant="subtitle2">Outcomes</Typography>
          <WysiwygEditor
            initValue={track.outcomes || ""}
            handleOnChange={(value) => updateField("outcomes", value)}
            readOnly={disabled}
          />
        </Box>
      </Stack>
    );
  };

  return (
    <Grid container mt={2}>
      <Grid item xs={12} px={1} mb={3}>
        {renderHeader()}
      </Grid>
      {saving && (
        <Grid item xs={12} px={1} mb={3}>
          <LinearProgress />
        </Grid>
      )}
      {error && (
        <Grid item xs={12} px={1} mb={3}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      <Grid item xs={6} px={1}>
        {renderLeftForm()}
      </Grid>
      <Grid item xs={6} px={1}>
        {renderRightForm()}
      </Grid>
    </Grid>
  );
}
