import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Typography,
  CircularProgress,
  Stack,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Alert,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { useHistory } from "react-router-dom";
import { fixUndefinedValues } from "../lessons/utils";
import { multipleChoiceQuestionsActions } from "../store/multipleChoiceQuestionsSlice";
import { codeEditorActions } from "../store/codeEditorSlice";
import { lessonDataActions } from "../store/lessonDataSlice";

const withLessonData = (WrappedComponent) => {
  function WithLessonData(props) {
    const history = useHistory();
    const params = useParams();
    const lessonId = params.lessonId;
    const screenId = params.screenId;

    const lessonData = useSelector((state) => state.lessonData);
    const lesson = lessonData.lessons[lessonId];
    const dispatch = useDispatch();

    const [justLoaded, setJustLoaded] = useState(!lesson);
    const [loading, setLoading] = useState(!lesson);
    const [loadMessage, setLoadMessage] = useState("");
    const [error, setError] = useState("");

    const loadLesson = useCallback(
      async (isMounted) => {
        setError("");
        setLoading(true);

        try {
          // Load the lesson
          setLoadMessage(`Loading lesson data...`);
          let response = await fetch(`/api/lesson/${lessonId}`);
          if (!response.ok) {
            throw new Error(
              `Failed to load lesson data: ${response.statusText}`
            );
          }
          let lesson = await response.json();
          lesson = fixUndefinedValues(lesson);
          if (isMounted) {
            dispatch(
              lessonDataActions.setLessonData({
                lesson,
                backend: true,
              })
            );
          }

          // Load the screens
          setLoadMessage(`Loading screen data...`);
          response = await fetch(`/api/lesson/${lessonId}/screens`);
          if (!response.ok) {
            throw new Error(
              `Failed to fetch lesson screens: ${response.statusText}`
            );
          }
          const screens = await response.json();
          if (isMounted) {
            dispatch(
              lessonDataActions.setLessonScreens({
                lessonId: lesson.id,
                screens,
                backend: true,
              })
            );
            dispatch(codeEditorActions.initializeScreens(screens));
          }

          // Load multiple choice questions
          setLoadMessage(`Loading multiple choice question data...`);
          await Promise.all(
            screens.map(async (screen) => {
              const mcqResponse = await fetch(
                `/api/lesson/${lessonId}/screen/${screen.id}/mcq`
              );
              const multipleChoiceQuestions = await mcqResponse.json();
              if (isMounted) {
                dispatch(
                  multipleChoiceQuestionsActions.setScreenMcqs({
                    screenId: screen.id,
                    multipleChoiceQuestions,
                  })
                );
              }
            })
          );
        } catch (error) {
          if (isMounted) {
            setError("An error occurred while trying to load the lesson data.");
          }
        } finally {
          if (isMounted) {
            setLoading(false);
            setLoadMessage("");
          }
        }
      },
      [lessonId, dispatch]
    );

    useEffect(() => {
      let isMounted = true;

      if (!lesson) {
        loadLesson(isMounted);
      }

      return () => {
        isMounted = false;
      };
    }, [lesson, loadLesson]);

    if (loading) {
      return (
        <Box display="flex" alignItems="center" flexDirection="column">
          <AppBar position="static">
            <Toolbar>
              <IconButton color="inherit" onClick={() => history.push("/")}>
                <HomeIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Stack justifyContent="center" alignItems="center" spacing={1} my={2}>
            <Typography>{loadMessage}</Typography>
            <CircularProgress />
          </Stack>
        </Box>
      );
    }

    if (error) {
      return (
        <Box display="flex" alignItems="center" flexDirection="column">
          <AppBar position="static">
            <Toolbar>
              <IconButton color="inherit" onClick={() => history.push("/")}>
                <HomeIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Stack justifyContent="center" alignItems="center" spacing={1} my={2}>
            <Alert severity="error">{error}</Alert>
          </Stack>
        </Box>
      );
    }

    if (screenId) {
      const screen = lessonData.screens[screenId];
      return <WrappedComponent {...props} lesson={lesson} screen={screen} />;
    }

    return <WrappedComponent {...props} lesson={lesson} />;
  }

  return WithLessonData;
};

export default withLessonData;
