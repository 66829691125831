import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormGroup,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Button,
  Switch,
  FormControlLabel,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useDispatch, useSelector } from "react-redux";
import { lessonDataActions } from "../store/lessonDataSlice";
import { errorActions } from "../store/errorSlice";
import ConfirmDelete from "../ConfirmDelete";
import SaveModal from "../general/SaveModal";
import { useHistory } from "react-router-dom";
import DropdownButton from "../general/DropdownButton";
import { DEFAULT_REMOTE_RUN_CODE } from "./code-tabs/constants";

export default function ScreenFooter({ lesson, screen, saveScreen }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const screenStatus = useSelector((state) => state.lessonData.screenStatus)[
    screen.id
  ];

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [saveModalIsOpen, setSaveModalIsOpen] = useState(false);

  const [duplicating, setDuplicating] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const renderToggle = (label, propertyName) => {
    const isRunAndDeploy = propertyName === "run_and_deploy";
    let checked = screen[propertyName];
    if (isRunAndDeploy) checked = !!screen.remote_run_code;
    return (
      <Box pl={1}>
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                disabled={screenStatus.saving}
                checked={checked}
                onChange={(event) => {
                  let value = event.target.checked;
                  if (isRunAndDeploy)
                    value = value ? DEFAULT_REMOTE_RUN_CODE : "";
                  dispatch(
                    lessonDataActions.updateScreenField({
                      screenId: screen.id,
                      field: isRunAndDeploy ? "remote_run_code" : propertyName,
                      value: value,
                    })
                  );
                }}
              />
            }
            label={label}
            labelPlacement="end"
          />
        </FormControl>
      </Box>
    );
  };

  const deleteScreen = async () => {
    if (deleting) return;
    setDeleting(true);
    try {
      const response = await fetch(
        `/api/lesson/${lesson.id}/screen/${screen.id}`,
        { method: "DELETE" }
      );
      const responseData = await response.json();
      if (responseData.error) {
        dispatch(
          errorActions.setScreenError({
            screenId: screen.id,
            error: responseData.error,
          })
        );
      } else {
        dispatch(
          lessonDataActions.setLessonScreens({
            lessonId: lesson.id,
            screens: responseData,
            backend: true,
          })
        );
        history.push(`/lesson/${lesson.id}`);
      }
      setDeleting(false);
    } catch (error) {
      console.log(error);
      dispatch(
        errorActions.setScreenError({
          screenId: screen.id,
          error: "An error occurred while deleting the screen.",
        })
      );
      setDeleting(false);
    }
  };

  const duplicateScreen = async () => {
    if (duplicating) return;
    setDuplicating(true);
    try {
      const response = await fetch(`/api/screen/${screen.id}/clone`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
      const responseData = await response.json();
      const { screens, newScreenId, error } = responseData;
      if (error) {
        dispatch(
          errorActions.setScreenError({
            screenId: screen.id,
            error,
          })
        );
      } else {
        dispatch(
          lessonDataActions.setLessonScreens({
            lessonId: lesson.id,
            screens: screens,
            backend: true,
          })
        );
        history.push(`/lesson/${lesson.id}/screen/${newScreenId}`);
      }
      setDuplicating(false);
    } catch (error) {
      console.log(error);
      dispatch(
        errorActions.setScreenError({
          screenId: screen.id,
          error: "An error occurred while duplicating the screen.",
        })
      );
      setDuplicating(false);
    }
  };

  return (
    <Box
      className="editor-footer dq-shadow"
      mt={{ xs: 3, md: 2 }}
      py={2}
      sx={{ boxShadow: 3 }}
      display="flex"
      gap={2}
      alignItems="center"
    >
      <Box
        display="flex"
        gap={2}
        justifyContent="space-between"
        flexGrow={1}
        px={2}
      >
        <FormGroup className="editor-footer-form">
          <TextField
            className="screen-title-input dq-w-full"
            variant="outlined"
            name="title"
            label="Title"
            value={screen.title}
            fullWidth
            onChange={(event) =>
              dispatch(
                lessonDataActions.updateScreenField({
                  screenId: screen.id,
                  field: "title",
                  value: event.target.value,
                })
              )
            }
            disabled={screenStatus.saving}
          />
          <FormControl variant="outlined" margin="dense">
            <InputLabel id="type-label">Type</InputLabel>
            <Select
              labelId="type-label"
              value={screen.type}
              label="Type"
              size="small"
              onChange={(event) =>
                dispatch(
                  lessonDataActions.updateScreenField({
                    screenId: screen.id,
                    field: "type",
                    value: event.target.value,
                  })
                )
              }
              disabled={screenStatus.saving}
            >
              <MenuItem key="text" value="text">
                <ListItemText primary="text" />
              </MenuItem>
              <MenuItem key="code" value="code">
                <ListItemText primary="code" />
              </MenuItem>
              <MenuItem key="takeaways" value="takeaways">
                <ListItemText primary="takeaways" />
              </MenuItem>
            </Select>
          </FormControl>
          {screen.type === "code" &&
            renderToggle("Experimental", "is_experimental")}
          {screen.type === "code" && renderToggle("Error Okay", "error_okay")}
          {screen.type === "code" &&
            renderToggle("Dynamic Answer", "dynamic_answer")}
          {screen.type === "code" &&
            renderToggle("Run and Deploy", "run_and_deploy")}
        </FormGroup>
      </Box>

      <Box
        display="flex"
        gap={2}
        alignItems="center"
        alignContent="center"
        px={2}
      >
        <Button
          variant="contained"
          flexShrink={0}
          color="secondary"
          onClick={saveScreen}
          disabled={screenStatus.saving}
        >
          Save screen
        </Button>
        <DropdownButton
          buttonText={<MoreHorizIcon />}
          color="secondary"
          isLoading={screenStatus.saving}
          actions={[
            { name: "Delete screen", action: () => setDeleteModalIsOpen(true) },
            {
              name: "Duplicate screen",
              action: duplicateScreen,
            },
            {
              name: "Save with message",
              action: () => setSaveModalIsOpen(true),
            },
          ]}
        />
      </Box>
      <ConfirmDelete
        type="Screen"
        name={screen.title}
        open={deleteModalIsOpen}
        delete={deleteScreen}
        onClose={() => setDeleteModalIsOpen(false)}
      />
      <SaveModal
        isOpen={saveModalIsOpen}
        close={() => setSaveModalIsOpen(false)}
        save={saveScreen}
      />
    </Box>
  );
}
