import React, { useState, useEffect, useCallback } from "react";
import Modal from "./Modal";
import {
  Box,
  Stack,
  Button,
  Chip,
  Select,
  MenuItem,
  CircularProgress,
  Alert,
} from "@mui/material";
import ListInputTextField from "./ListInputTextField";
import Loader from "./Loader";

export default function ImportModal(props) {
  const [sequences, setSequences] = useState([]);
  const [loadingReleases, setLoadingReleases] = useState(false);
  const [error, setError] = useState("");
  const [releases, setReleases] = useState([]);
  const [selectedRelease, setSelectedRelease] = useState("");
  const [importing, setImporting] = useState(false);

  const loadReleases = useCallback(async () => {
    setLoadingReleases(true);
    setError("");
    try {
      const response = await fetch(`/api/releases`);
      const releases = await response.json();
      if (releases.error) {
        setError(releases.error);
      } else {
        setReleases(releases);
      }
    } catch (error) {
      setError("Could not load releases. Try again later.");
    }
    setLoadingReleases(false);
  }, []);

  const handleImport = async () => {
    setImporting(true);
    setError("");
    try {
      const response = await props.import(selectedRelease, sequences);
      const data = await response.json();
      if (data.error) {
        setError(data.error);
      } else {
        props.closeHandler();
      }
    } catch {
      setError("An error occurred while importing. Try again later.");
    }
    setImporting(false);
  };

  useEffect(() => {
    loadReleases();
  }, [loadReleases]);

  useEffect(() => {
    if (!props.isOpen) {
      setSequences([]);
      setLoadingReleases(false);
      setError("");
    }
  }, [props.isOpen]);

  const renderReleases = () => {
    if (loadingReleases) {
      return <Loader loadMessage="Loading releases..." />;
    }
    return (
      <Select
        value={selectedRelease}
        onChange={(event) => setSelectedRelease(event.target.value)}
        disabled={importing}
      >
        <MenuItem value={""} disabled={true}>
          Select Release
        </MenuItem>
        {releases.map((release) => (
          <MenuItem key={release.id} value={release.id}>
            {release.name}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const renderInput = () => {
    return (
      <Box my={1}>
        <ListInputTextField
          fullWidth
          label="Sequences"
          onChange={setSequences}
          disabled={importing}
        />
        <Stack direction="row" flexWrap="wrap" my={1}>
          {sequences.map((element, index) => (
            <Chip key={`item-${index}`} label={element} sx={{ m: 0.1 }} />
          ))}
        </Stack>
      </Box>
    );
  };

  const renderContent = () => (
    <Stack spacing={1}>
      {error && <Alert severity="error">{error}</Alert>}
      {renderReleases()}
      {renderInput()}
    </Stack>
  );

  const renderActions = () => (
    <Stack direction="row" justifyContent="space-between" flexGrow={1}>
      <Button
        color="secondary"
        variant="contained"
        onClick={props.closeHandler}
      >
        Cancel
      </Button>
      <Button
        color="primary"
        variant="contained"
        disabled={sequences.length === 0 || selectedRelease === ""}
        onClick={handleImport}
        disabled={importing}
      >
        {importing ? <CircularProgress size={24} /> : "Import"}
      </Button>
    </Stack>
  );

  return (
    <Modal
      isOpen={props.isOpen}
      closeHandler={props.closeHandler}
      title={props.title || "Import"}
      content={renderContent()}
      actions={renderActions()}
    />
  );
}
