import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import Loader from "./Loader";
import {
  Typography,
  Stack,
  Button,
  Select,
  MenuItem,
  Box,
  List,
  ListItemText,
  ListItem,
  IconButton,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PropTypes from "prop-types";

export default function MakeSelectionModal(props) {
  const [elements, setElements] = useState([]);
  const [selection, setSelection] = useState([]);
  const [selectedElement, setSelectedElement] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [adding, setAdding] = useState(false);
  const [addResults, setAddResults] = useState([]);
  const [currentElement, setCurrentElement] = useState();

  const resetState = () => {
    setElements([]);
    setSelection([]);
    setSelectedElement("");
    setIsLoading(false);
    setAdding(false);
    setAddResults([]);
    setCurrentElement();
  };

  const selectionIds = selection.map((element) => element.id);

  const handleSelect = (event) => {
    const element = event.target.value;
    setSelectedElement(element);
  };

  const handleAddToSelection = () => {
    if (!selectedElement) return;
    if (selectionIds.includes(selectedElement.id)) return;
    setSelection([...selection, selectedElement]);
    setSelectedElement("");
  };

  const handleDelete = (elementToDelete) => {
    setSelection(
      selection.filter((element) => element.id !== elementToDelete.id)
    );
  };

  const handleAdd = async () => {
    if (selection.length === 0) return;
    setAdding(true);
    setAddResults([]);
    setCurrentElement();
    const results = [];
    for (let i = 0; i < selection.length; i++) {
      const element = selection[i];
      try {
        setCurrentElement(element);
        const result = await props.addElement(element);
        results.push(
          result.error
            ? { error: true, msg: result.error }
            : {
                error: false,
                msg: `${props.renderElement(element)} successfully added`,
              }
        );
      } catch (error) {
        results.push({
          error: true,
          msg: `An error occurred while adding ${props.renderElement(
            element
          )}. Try again later.`,
        });
      }
    }
    setAddResults(results);
  };

  const renderAdding = () => {
    if (!currentElement) {
      return <Loader loadMessage={`Preparing to add selection...`} />;
    }
    return (
      <Stack direction="column" spacing={1}>
        {addResults.length === 0 && (
          <Loader
            loadMessage={`Adding ${props.renderElement(currentElement)}...`}
          />
        )}
        {addResults.map((result, index) => (
          <Alert
            key={`add-result-${index}`}
            severity={result.error ? "error" : "success"}
          >
            {result.msg}
          </Alert>
        ))}
      </Stack>
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loader loadMessage={props.loadMessage} />;
    }

    if (adding) {
      return renderAdding();
    }

    if (elements.length === 0) {
      return <Typography>{props.noElementsText}</Typography>;
    }
    return (
      <Box>
        <Typography variant="subtitle2">{props.selectHeader}</Typography>
        <Stack direction="row">
          <Select
            value={selectedElement}
            onChange={handleSelect}
            fullWidth
            disabled={adding}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {elements
              .filter((element) => !selectionIds.includes(element.id))
              .map((element) => (
                <MenuItem key={`element-${element.id}`} value={element}>
                  {props.renderElement(element)}
                </MenuItem>
              ))}
          </Select>
          <IconButton
            color="primary"
            disabled={
              !selectedElement || selectionIds.includes(selectedElement.id)
            }
            onClick={handleAddToSelection}
          >
            <AddIcon />
          </IconButton>
        </Stack>
        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          {props.selectionHeader}
        </Typography>
        {selection.length === 0 && (
          <Typography textAlign="center">{props.emptySelectionText}</Typography>
        )}
        <List>
          {selection.map((element) => (
            <ListItem
              key={`selected-element-${element.id}`}
              secondaryAction={
                <IconButton
                  edge="end"
                  color="secondary"
                  disabled={adding}
                  onClick={() => handleDelete(element)}
                >
                  <DeleteForeverIcon />
                </IconButton>
              }
            >
              <ListItemText
                key={element.id}
                primary={props.renderElement(element)}
                secondary={props.renderElementSecondary(element)}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  const renderActions = () => (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      flexGrow={1}
      mx={2}
    >
      <Button
        color="secondary"
        variant="contained"
        onClick={props.closeHandler}
      >
        {addResults.length === 0 ? "Cancel" : "Close"}
      </Button>
      {!adding && (
        <Button
          color="primary"
          variant="contained"
          disabled={selection.length === 0}
          onClick={handleAdd}
        >
          Add Selection
        </Button>
      )}
    </Stack>
  );

  const loadElements = async () => {
    try {
      resetState();
      setIsLoading(true);
      const elements = await props.loadElements();
      setElements(elements);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!props.isOpen) return;
    loadElements();
  }, [props.isOpen]);

  return (
    <Modal
      isOpen={props.isOpen}
      closeHandler={props.closeHandler}
      title={props.title}
      content={renderContent()}
      actions={renderActions()}
    />
  );
}

MakeSelectionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  addElement: PropTypes.func.isRequired,
  loadElements: PropTypes.func.isRequired,
  renderElement: PropTypes.func.isRequired,
  renderElementSecondary: PropTypes.func,
  title: PropTypes.string,
  selectHeader: PropTypes.string,
  selectionHeader: PropTypes.string,
  loadingMessage: PropTypes.string,
  emptySelectionText: PropTypes.string,
  noElementsText: PropTypes.string,
};

MakeSelectionModal.defaultProps = {
  title: "Make selection",
  renderElementSecondary: () => "",
  selectHeader: "Select elements to add",
  selectionHeader: "Selected elements",
  loadMessage: "Loading...",
  emptySelectionText: "Empty selection",
  noElementsText: "No elements",
};
