import React, { useState, useEffect } from "react";
import Loader from "../general/Loader";
import {
  Container,
  Box,
  Grid,
  Button,
  Stack,
  Typography,
  IconButton,
  Alert,
  Tooltip,
  LinearProgress,
} from "@mui/material";
import SearchableTable from "../general/SearchableTable";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import WorkIcon from "@mui/icons-material/Work";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MakeSelectionModal from "../general/MakeSelectionModal";
import NewEntryForm from "../general/NewEntryForm";

export default function CourseLessons(props) {
  const history = useHistory();

  const course = props.course;

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [courseLessons, setCourseLessons] = useState([]);
  const [addingLessons, setAddingLessons] = useState(false);
  const [creatingLesson, setCreatingLesson] = useState(false);

  const FILTERS = [];
  const COLUMNS = [
    {
      key: "move",
      label: "",
      type: "component",
      render: (lesson, col, rowIndex, colIndex) => {
        return (
          <Stack direction="column">
            {rowIndex > 0 && (
              <IconButton
                onClick={() => handleMove(lesson, true)}
                disabled={course.is_main}
              >
                <ArrowDropUpIcon />
              </IconButton>
            )}
            {rowIndex < courseLessons.length - 1 && (
              <IconButton
                onClick={() => handleMove(lesson, false)}
                disabled={course.is_main}
              >
                <ArrowDropDownIcon />
              </IconButton>
            )}
          </Stack>
        );
      },
    },
    {
      key: "is_project",
      label: "",
      type: "component",
      render: (lesson, col) =>
        lesson.is_project ? (
          <Tooltip title="Project">
            <WorkIcon />
          </Tooltip>
        ) : (
          <Tooltip title="Lesson">
            <MenuBookIcon />
          </Tooltip>
        ),
    },
    {
      key: "sequence",
      label: "Number",
      type: "text",
    },
    {
      key: "title",
      label: "Title",
      type: "text",
    },
    {
      key: "description",
      label: "Description",
      type: "text",
    },
    {
      key: "last_updated",
      label: "Updated",
      type: "text",
    },
    {
      key: "edit",
      label: "",
      type: "component",
      render: (lesson, col) => (
        <IconButton
          onClick={() => history.push(`/lesson/${lesson.id}`)}
          disabled={course.is_main}
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      key: "delete",
      label: "",
      type: "component",
      render: (lesson, col) => (
        <IconButton
          color="secondary"
          onClick={() => handleDelete(lesson)}
          disabled={course.is_main}
        >
          <CloseIcon />
        </IconButton>
      ),
    },
  ];

  const handleMove = async (lesson, swapWithPrevious) => {
    try {
      setError("");
      setIsLoading(true);
      const response = await fetch(
        `/api/course/${course.id}/lesson/${lesson.id}/swap`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            swap_with_previous: swapWithPrevious,
          }),
        }
      );
      const data = await response.json();
      if (data.error) {
        setError(data.error);
      } else {
        setCourseLessons(data);
      }
      setIsLoading(false);
    } catch (error) {
      setError(
        "An error occurred while trying to move the lesson. Try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (lesson) => {
    try {
      setError("");
      setIsLoading(true);
      const response = await fetch(
        `/api/course/${course.id}/lesson/${lesson.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        }
      );
      const data = await response.json();
      if (data.error) {
        setError(data.error);
      } else {
        setCourseLessons(data);
      }
    } catch (error) {
      setError(
        "An error occurred while trying to delete the lesson. Try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getCourseLessons = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`/api/course/${course.id}/lessons`);
      const courseLessons = await response.json();
      if (courseLessons.error) {
        setError(courseLessons.error);
      } else {
        setCourseLessons(courseLessons);
      }
    } catch (error) {
      setError(
        "An error occurred while loading the course lessons. Try again later."
      );
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setError("");
    getCourseLessons();
  }, []);

  if (isLoading) {
    return <Loader loadMessage="Loading lessons..." />;
  }

  const addLessonsCloseHandler = () => {
    setAddingLessons(false);
    getCourseLessons();
  };

  const handleCreate = async (sequence, name) => {
    let lessonId = null;
    try {
      const createResponse = await fetch("/api/lessons", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sequence: sequence,
          title: name,
          release_id: props.course.release_id,
        }),
      });
      const createData = await createResponse.json();
      if (createData.error) {
        setCreatingLesson(false);
        setError(createData.error);
        return;
      }
      lessonId = createData.id;
    } catch (error) {
      setError("An error occurred while creating the lesson. Try again later.");
      return;
    }

    try {
      const addResponse = await fetch(
        `/api/course/${props.course.id}/lesson/${lessonId}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({}),
        }
      );
      const addData = await addResponse.json();
      setCreatingLesson(false);
      if (addData.error) {
        setError(addData.error);
      } else {
        history.push(`/lesson/${lessonId}`);
      }
    } catch (error) {
      setCreatingLesson(false);
      setError(
        "The lesson was created but an error occurred while adding it to the course."
      );
    }
  };

  const loadAllLessons = async () => {
    const response = await fetch(`/api/lessons`);
    return await response.json();
  };

  const addLessonToCourse = async (lesson) => {
    const response = await fetch(
      `/api/course/${props.course.id}/lesson/${lesson.id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      }
    );
    return await response.json();
  };

  return (
    <Container sx={{ pb: 3 }}>
      <MakeSelectionModal
        title="Add Lessons to Course"
        selectHeader="Select Lesson"
        selectionHeader="Selected Lessons"
        loadMessage="Loading Lessons..."
        emptySelectionText="No Lessons Selected"
        noElementsText="No Lessons"
        isOpen={addingLessons}
        closeHandler={addLessonsCloseHandler}
        renderElement={(lesson) =>
          `${lesson.sequence} - ${lesson.title} (${lesson.release_name})`
        }
        loadElements={loadAllLessons}
        addElement={addLessonToCourse}
      />
      <Box my={5}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="h6">Course Content</Typography>
          </Grid>
          <Grid item>
            <Stack direction="row" spacing={2}>
              <NewEntryForm
                variant="outlined"
                newLabel="New Lesson"
                addLabel="Create Lesson"
                nameLabel="Lesson Name"
                handleAdd={handleCreate}
                disabled={course.is_main}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={() => setAddingLessons(true)}
                disabled={course.is_main}
              >
                Add Lessons
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      {creatingLesson && <LinearProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      <SearchableTable
        cols={COLUMNS}
        rows={courseLessons}
        isLoading={isLoading}
        searchable={false}
        filters={FILTERS}
        rowsPerPage={courseLessons.length}
      />
    </Container>
  );
}
