import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import OutputSection from "./OutputSection";
import VariableSection from "./VariableSection";
import ChecksSection from "./ChecksSection";
import DynamicChecksSection from "./DynamicChecksSection";

export default function CodeOutput() {
  const params = useParams();
  const lessonId = params.lessonId;
  const screenId = params.screenId;

  const lessonData = useSelector((state) => state.lessonData);
  const lesson = lessonData.lessons[lessonId];
  const screen = lessonData.screens[screenId];

  return (
    <Box className="editor-code-output">
      <OutputSection lesson={lesson} screen={screen} />
      <ChecksSection lesson={lesson} screen={screen} />
      <DynamicChecksSection lesson={lesson} screen={screen} />
      <VariableSection lesson={lesson} screen={screen} />
    </Box>
  );
}
