import { createSlice } from "@reduxjs/toolkit";

export const initialScreenState = {
  currentTab: null,
  customRunCode: "",
  customTest: {
    code: "",
    output: {},
    variables: [],
    customCheckOutput: null,
  },
  runResult: {
    checks: {},
    output: {},
    variables: [],
    customCheckOutput: null,
  },
  runDeployResult: null,
  displayVariables: [],
  selectedVariables: [],
};

const initialState = {};

const codeEditorSlice = createSlice({
  name: "codeEditorSlice",
  initialState: initialState,
  reducers: {
    reset() {
      return initialState;
    },
    initializeScreen(state, action) {
      const screenId = action.payload;
      state[screenId] = { ...initialScreenState };
    },
    initializeScreens(state, action) {
      const screens = action.payload;
      screens.forEach((screen) => {
        state[screen.id] = { ...initialScreenState };
      });
    },
    resetCustomTest(state, action) {
      const screenId = action.payload;
      state[screenId].customTest = initialScreenState.customTest;
    },
    updateCustomTest(state, action) {
      const screenId = action.payload.screenId;
      state[screenId].customTest = {
        ...state[screenId].customTest,
        ...action.payload.customTest,
      };
    },
    updateRunResult(state, action) {
      const screenId = action.payload.screenId;
      state[screenId].runResult = {
        ...state[screenId].runResult,
        ...action.payload.runResult,
      };
    },
    updateRunDeployResult(state, action) {
      const screenId = action.payload.screenId;
      state[screenId].runDeployResult = {
        ...state[screenId].runDeployResult,
        ...action.payload.runDeployResult,
      };
    },
    setCurrentTab(state, action) {
      const screenId = action.payload.screenId;
      state[screenId].currentTab = action.payload.currentTab;
    },
    setSelectedVariables(state, action) {
      const screenId = action.payload.screenId;
      state[screenId].selectedVariables = action.payload.selectedVariables;
    },
    setCustomRunCode(state, action) {
      const screenId = action.payload.screenId;
      state[screenId].customRunCode = action.payload.customRunCode;
    },
  },
});

export const codeEditorActions = codeEditorSlice.actions;

export default codeEditorSlice.reducer;
